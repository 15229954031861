import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
const Component = ({ item, num }) => {
  const images = item?.imageMobile
    ? [
        item.imageMobile.localFile.childImageSharp.fluid,
        {
          ...item.image.localFile.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ]
    : item.image.localFile.childImageSharp.fluid
    ? item.image.localFile.childImageSharp.fluid
    : false
  const containerClass = (item.title || item.text || item.link) ? 'container' : 'd-none d-md-block container';
  return (
    <div className={`section bg-text`}>
      {images && (
        <Img
          fluid={images}
          className="no-padding-image"
          alt={item.image.altText}
          objectFit="cover"
          objectPosition="50% 50%"
        />
      )}
      <div className={containerClass}>
        <div className="row align-items-center">
          <div className="col-12 col-md-6">
            {item?.title && <h2>{parse(item.title)}</h2>}
            {item?.text && <div>{parse(item.text)}</div>}

            {item?.link && (
              <Link
                data-color={item.linkColor}
                className="btn"
                to={item.link.url}
              >
                {item.link.title}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )}

export default Component
