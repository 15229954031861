import React from 'react'
import parse from 'html-react-parser'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Parallax from './Parallax'

function hexToRGB(hexColor, alpha) {
  const hex = hexColor.toUpperCase()
  const h = '0123456789ABCDEF'
  const r = h.indexOf(hex[1]) * 16 + h.indexOf(hex[2])
  const g = h.indexOf(hex[3]) * 16 + h.indexOf(hex[4])
  const b = h.indexOf(hex[5]) * 16 + h.indexOf(hex[6])
  if (alpha) return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  else return 'rgb(' + r + ', ' + g + ', ' + b + ')'
}

const BeautifulButton = styled.div`
  background-color: ${(props) => props.color} !important;
  &:hover {
    background-color: ${(props) => props.hoverColor} !important;
  }
`

const Component = ({ item, num, pageContext }) => {


  const hasVideo =
    item?.webm?.localFile?.publicURL && item?.mp4?.localFile?.publicURL

  const images = item?.imageMobile
    ? [
        item.imageMobile.localFile.childImageSharp.fluid,
        {
          ...item.image.localFile.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ]
    : item.image.localFile.childImageSharp.fluid
    ? item.image.localFile.childImageSharp.fluid
    : false
  return (
    <>
      <div className={`section top ${item?.cssClass ? item?.cssClass : ''}`}>
        <div className="wrap">
          {images && (
            <Parallax>
              <Img
                fluid={images}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={item.image.altText}
                loading="eager"
                fadeIn={false}
                className="no-padding-image"
              />
            </Parallax>
          )}
          <div className="container">
            <div
              className={`row main align-items-end align-items-md-center justify-content-${item.position}`}
            >
              <div className={`col-12 ${hasVideo ? 'col-md-6' : 'col-md-6'}`}>
                <div className="col-12">
                  {item?.title && <h1>{parse(item.title)}</h1>}
                </div>
                <div className="col-12 col-md-10">
                  {item?.text && (
                    <div className="d-none d-md-block">{parse(item.text)}</div>
                  )}
                </div>

                {item.link && (
                  <Link to={item.link.url} target={item.link.target}>
                    <motion.button
                      className=""
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        padding: 0,
                      }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <BeautifulButton
                        color={
                          item.linkColor
                            ? hexToRGB(item.linkColor, 1)
                            : '#5dd5a3'
                        }
                        hoverColor={
                          item.linkColor
                            ? hexToRGB(item.linkColor, 0.7)
                            : 'transparent'
                        }
                        className="btn"
                      >
                        {item.link.title}
                      </BeautifulButton>
                    </motion.button>
                  </Link>
                )}
              </div>
              {hasVideo && (
                <div className="col-6 d-none d-md-flex">
                  <div className="ratio ratio-16x9">
                    <video
                      controls
                      poster={item?.videoPoster?.localFile?.publicURL}
                    >
                      {item?.webm?.localFile?.publicURL && (
                        <source
                          src={item.webm.localFile.publicURL}
                          type="video/webm"
                        />
                      )}
                      {item?.mp4?.localFile?.publicURL && (
                        <source
                          src={item.mp4.localFile.publicURL}
                          type="video/mp4"
                        />
                      )}
                    </video>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="wrap d-md-none">
          <div className="container">
            <div className="row mobile">
              {hasVideo && (
                <div className="col-12 d-md-none mb-4">
                  <div className="ratio ratio-16x9">
                    <video
                      controls
                      poster={item?.videoPoster?.localFile?.publicURL}
                    >
                      {item?.webm?.localFile?.publicURL && (
                        <source
                          src={item.webm.localFile.publicURL}
                          type="video/webm"
                        />
                      )}
                      {item?.mp4?.localFile?.publicURL && (
                        <source
                          src={item.mp4.localFile.publicURL}
                          type="video/mp4"
                        />
                      )}
                    </video>
                  </div>
                </div>
              )}
              {item?.text && <div className="col-12">{parse(item.text)}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Component
