import React from 'react'
import Img from 'gatsby-image'
import parse from 'html-react-parser'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
SwiperCore.use([Navigation, Pagination])

function Case({ data, item }) {
  return (
    <div className="row justify-content-between">
      <div className="col-12">
        <h2>
          {item.title} {data.title}
        </h2>
      </div>
      <div className={`col-12 col-md-6 order-1 order-md-0 case-content`}>
        {parse(data.content)}
      </div>
      <div className="col-12 col-md-5 order-0 order-md-1 case-image">
        {data?.case?.image && (
          <div className="row case-image-wrap justify-content-center">
            <div className="col-8 col-md-12">
              <div className="circle-wrap">
                <Img
                  fluid={data.case.image.localFile.childImageSharp.fluid}
                  className="no-padding-image circle-image"
                  alt={data.case.image.altText}
                />
              </div>
            </div>
          </div>
        )}
        <div className="quote-wrap">
          {data.case.quote.text && (
            <h4 className="quote-text">{data.case.quote.text}</h4>
          )}
          {data.case.quote.name && (
            <span className="quote-name">{data.case.quote.name}</span>
          )}
        </div>
      </div>
    </div>
  )
}

const Component = ({ item, cases, num }) => {
  const categories = item.category.map((category) => category.slug)
  const filteredCases = cases.nodes.filter((item) => {
    const caseCategories = item.caseCategories.nodes.map((term) => term.slug)
    return caseCategories.some((slug) => categories.includes(slug))
  })
  return (
    <div className={`section case`}>
      <div className="container">
        {filteredCases.length > 1 && (
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination
            autoHeight
          >
            {filteredCases !== null &&
              filteredCases.map((data, i, arr) => (
                <SwiperSlide key={data.id}>
                  <Case item={item} data={data} />
                </SwiperSlide>
              ))}
          </Swiper>
        )}
        {filteredCases.length === 1 &&
          filteredCases.map((data, i, arr) => <Case item={item} data={data} />)}
      </div>
    </div>
  )
}

export default Component
