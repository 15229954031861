import React from 'react'
import Img from 'gatsby-image'
import parse from 'html-react-parser'
const Component = ({ item, num }) => (
  <div className={`section contact`}>
    <div className="container">
      <div className="row">
        <div className="col-12">
          {item.title && <h3 className="title">{item.title}</h3>}
        </div>
      </div>
      <div className="row row-people">
        {item.people &&
          item.people.map((item, i) => (
            <div
              className="col-12 col-sm-6 col-md-4 person text-center"
              key={'people' + i}
            >
              {item?.image && (
                <div className="row justify-content-center">
                  <div className="col-8 col-md-12">
                    <div className="circle-wrap">
                      <Img
                        fluid={item.image.localFile.childImageSharp.fluid}
                        className="no-padding-image circle-image"
                        alt={item.image.altText}
                      />
                    </div>
                  </div>
                </div>
              )}
              {item.name && <h4 className="name">{item.name}</h4>}
              <div className="person-info">{parse(item.text)}</div>
            </div>
          ))}
      </div>
    </div>
  </div>
)

export default Component
