import React from 'react'

const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="240.438"
    height="57.818"
    viewBox="0 0 240.438 57.818"
    className="d-none d-md-block"
  >
    <defs>
      <linearGradient
        id="a"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#227c89" />
        <stop offset="1" stopColor="#6fa8b9" />
      </linearGradient>
    </defs>
    <path
      d="M216.614,57.818H0L23.824,28.909,0,0H216.614l23.824,28.909Z"
      fill="url(#a)"
    />
  </svg>
)
const ArrowVert = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 33 124.5"
    enableBackground="new 0 0 33 124.5"
    xmlSpace="preserve"
    className="d-md-none"
  >
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="-380.0228"
      y1="353.6548"
      x2="-379.0228"
      y2="353.6548"
      gradientTransform="matrix(7.621038e-15 124.461 33 -2.020667e-15 -11654.1064 47298.0195)"
    >
      <stop offset="0" stopColor="#227c89" />
      <stop offset="1" stopColor="#6fa8b9" />
    </linearGradient>
    <path
      fill="url(#SVGID_1_)"
      d="M0,112.1V0l16.5,12.3L33,0v112.1l-16.5,12.3L0,112.1z"
    />
  </svg>
)

const Component = () => (
  <div className={`section timeline`}>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2 className="title">Company Timeline</h2>
        </div>
      </div>
      <div className="row">
        <div className="col item">
          <h4>1970s</h4>
          <Arrow />
          <ArrowVert />
          <p>Machine invented in the late 1970s</p>
        </div>
        <div className="col item">
          <h4>1982</h4>
          <Arrow />
          <ArrowVert />
          <p>Limited Company start 1982</p>
        </div>
        <div className="col item">
          <h4>1984</h4>
          <Arrow />
          <ArrowVert />
          <p>Moved in to current premises 1984</p>
        </div>
        <div className="col item">
          <h4>1990</h4>
          <Arrow />
          <ArrowVert />
          <p>
            Partnership with Nordgren for machines – first machine sold 1990
          </p>
        </div>
        <div className="col item">
          <h4>2012</h4>
          <Arrow />
          <ArrowVert />
          <p>Nordgren and Persson merge 2012</p>
        </div>
        <div className="col item">
          <h4>2018</h4>
          <Arrow />
          <ArrowVert />
          <p>Ceder Capital acquired Persson in 2018</p>
        </div>
      </div>
    </div>
  </div>
)

export default Component
