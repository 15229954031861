import React from 'react'
import Img from 'gatsby-image'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'
const Component = ({ item, num, pageContext }) => {
  return (
    <div className={`section triple`}>
      <div className="container">
        <div
          className="row block justify-content-md-between"
        >
          {item?.blocks &&
            item.blocks.map((block, i) => {
              return (
                <div key={`triple${i}`} className={`col-12 col-md-12 col-lg-4`}>
                  <div className="row align-items-center justify-content-center ">
                    <div className="text col-12 col-sm-6 col-md-6 col-lg-12">
                      {block?.title && <h2>{parse(block.title)}</h2>}
                      {block?.text && <div>{parse(block.text)}</div>}
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-12">
                      <div className="row">
                        <div className="col-12 order-2 order-sm-1">
                          <div className="ratio ratio-1x1">
                            {block?.image && (
                              <Img
                                fluid={
                                  block?.image?.localFile?.childImageSharp?.fluid
                                }
                                className="item-image"
                                alt={block.image.altText}
                              />
                            )}
                          </div>
                        </div>
                        <div className="mb-3 mb-sm-0 col-12 order-1 order-sm-2">
                          {block?.link && (
                            <motion.a
                              href={block.link.url}
                              className="btn"
                              whileTap={{ scale: 0.9 }}
                            >
                              {block.link.title}
                            </motion.a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default Component
