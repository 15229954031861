import React from 'react'
import Img from 'gatsby-image'
import parse from 'html-react-parser'
const Component = ({ item, num, pageContext }) => {

  const handleDownloadPdf = (title, url, target) => {
    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pdf_download',
        document_title: title,
        document_url: url,
      });
    } catch (error) {
      console.warn('GTM dataLayer push failed:', error);
    }

    if (target === '_blank') {
      window.open(url, target);
    } else {
      window.location.href = url;
    }
  };

  return (
    <div
      className={`section images ${item?.bgColor ? 'bg' : ''}`}
      style={{ backgroundColor: item?.bgColor ?? 'transparent' }}
    >
      <div className="container">
        {item?.blocks &&
          item.blocks.map((block, i) => {
            const order = (parseFloat(item?.position ?? 0) + i) % 2
            return (
              <div
                key={`image${num + i}`}
                className="row block justify-content-center justify-content-md-between align-items-center"
              >
                <div
                  className={`col-8 col-md-5 ${block.imageType} order-0 order-md-${order}`}
                >
                  {block?.image && (
                    <Img
                      fluid={block?.image?.localFile?.childImageSharp?.fluid}
                      className="item-image"
                      alt={block.image.altText}
                    />
                  )}
                  {block?.positionRight && (
                    <div className="button-row row col-12 mb-5 mb-md-0 justify-content-md-end">
                      {block?.button &&
                        <button
                          className='btn'
                          onClick={() => handleDownloadPdf(block.button.title, block.button.url, block.button.target)}
                        >
                          {block.button.title}
                        </button>
                      }
                      {block?.secondaryButton &&
                        <button
                          className='btn'
                          onClick={() => handleDownloadPdf(block.secondaryButton.title, block.secondaryButton.url, block.secondaryButton.target)}
                        >
                          {block.secondaryButton.title}
                        </button>
                      }
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-6 text">
                  {block?.title && <h2>{parse(block.title)}</h2>}
                  {block?.text && <div>{parse(block.text)}</div>}

                  {!block?.positionRight && (
                    <div className="button-row row">
                      {block?.button &&
                        <button
                          className='btn'
                          onClick={() => handleDownloadPdf(block.button.title, block.button.url, block.button.target)}
                        >
                          {block.button.title}
                        </button>
                      }
                      {block?.secondaryButton &&
                        <button
                          className='btn'
                          onClick={() => handleDownloadPdf(block.secondaryButton.title, block.secondaryButton.url, block.secondaryButton.target)}
                        >
                          {block.secondaryButton.title}
                        </button>
                      }
                    </div>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Component
