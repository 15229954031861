/* eslint-disable react/no-array-index-key */
import React from 'react'
import Top from './blocks/Top'
import BgText from './blocks/BgText'
import Circle from './blocks/Circle'
import Images from './blocks/Images'
import Sustainable from './blocks/Sustainable'
import Cta from './blocks/Cta'
import Case from './blocks/Case'
import Offices from './blocks/Offices'
import Industries from './blocks/Industries'
import Contact from './blocks/Contact'
import Timeline from './blocks/Timeline'
import LatestNews from './blocks/LatestNews'
import Form from './blocks/Form'
import Content from './blocks/Content'
import Video from './blocks/Video'
import Triple from './blocks/Triple'
import Double from './blocks/Double'
import Quadruple from './blocks/Quadruple'

const Component = (data) => {

  const { id } = data
  const content = data.content || []

  const layouts = {
    page_Pagedata_FlexContent_Top: Top,
    page_Pagedata_FlexContent_BgText: BgText,
    page_Pagedata_FlexContent_Circle: Circle,
    page_Pagedata_FlexContent_Images: Images,
    page_Pagedata_FlexContent_Sustainable: Sustainable,
    page_Pagedata_FlexContent_Cta: Cta,
    page_Pagedata_FlexContent_Case: Case,
    page_Pagedata_FlexContent_Offices: Offices,
    page_Pagedata_FlexContent_Industries: Industries,
    page_Pagedata_FlexContent_Contact: Contact,
    page_Pagedata_FlexContent_Timeline: Timeline,
    page_Pagedata_FlexContent_News: LatestNews,
    page_Pagedata_FlexContent_Form: Form,
    page_Pagedata_FlexContent_Content: Content,
    page_Pagedata_FlexContent_Video: Video,
    page_Pagedata_FlexContent_Triple: Triple,
    page_Pagedata_FlexContent_Double: Double,
    page_Pagedata_FlexContent_Quadruple: Quadruple,
    page_default: Content,
  }

  return (
    <>
      {content.map((item, i, arr) => {
        const layoutType = item?.fieldGroupName
        const ComponentTag = layouts[layoutType] ? layouts[layoutType] : layouts['page_default']
        return (
          <ComponentTag
            item={item}
            key={id + i + layoutType}
            arr={arr}
            num={i}
            {...data} />
        )
      })}
    </>
  )
}

export default Component
