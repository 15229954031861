import React, { useEffect } from 'react'
import Top from './blocks/Top'
import BgText from './blocks/BgText'
import Circle from './blocks/Circle'
import Images from './blocks/Images'
import Sustainable from './blocks/Sustainable'
import Cta from './blocks/Cta'
import Case from './blocks/Case'
import Offices from './blocks/Offices'
import Industries from './blocks/Industries'
import Contact from './blocks/Contact'
import Timeline from './blocks/Timeline'
import LatestNews from './blocks/LatestNews'
import Form from './blocks/Form'
import Content from './blocks/Content'
import Video from './blocks/Video'
import Triple from './blocks/Triple'
import Double from './blocks/Double'
import Quadruple from './blocks/Quadruple'

const Component = (data) => {

    const { id } = data
    const content = data.content || []

    const layouts = {

        page_Pagedataaccordion_tab_FlexContent_Top: Top,
        page_Pagedataaccordion_tab_FlexContent_BgText: BgText,
        page_Pagedataaccordion_tab_FlexContent_Circle: Circle,
        page_Pagedataaccordion_tab_FlexContent_Images: Images,
        page_Pagedataaccordion_tab_FlexContent_Sustainable: Sustainable,
        page_Pagedataaccordion_tab_FlexContent_Cta: Cta,
        page_Pagedataaccordion_tab_FlexContent_Case: Case,
        page_Pagedataaccordion_tab_FlexContent_Offices: Offices,
        page_Pagedataaccordion_tab_FlexContent_Industries: Industries,
        page_Pagedataaccordion_tab_FlexContent_Contact: Contact,
        page_Pagedataaccordion_tab_FlexContent_Timeline: Timeline,
        page_Pagedataaccordion_tab_FlexContent_News: LatestNews,
        page_Pagedataaccordion_tab_FlexContent_Form: Form,
        page_Pagedataaccordion_tab_FlexContent_Content: Content,
        page_Pagedataaccordion_tab_FlexContent_Video: Video,
        page_Pagedataaccordion_tab_FlexContent_Triple: Triple,
        page_Pagedataaccordion_tab_FlexContent_Double: Double,
        page_Pagedataaccordion_tab_FlexContent_Quadruple: Quadruple,
        page_default: Content,

    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 575) {
                // Add 'active' class to the '.accordion_item' and '.toggle_button' that have 'data-target="target-0"'
                const targetElements = document.querySelectorAll('.accordion_item[data-target="target-0"], .toggle_button[data-target="target-0"]');
                targetElements.forEach((element) => {
                    element.classList.add('active');
                });
            } else {
                // Remove 'active' class from all '.accordion_item' and '.toggle_button' elements
                document.querySelectorAll('.accordion_item, .toggle_button').forEach((item) => {
                    item.classList.remove('active');
                });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div className={'accordion_wrapper'}>
                <ul>
                    {

                        content.map((item, i, arr) => {
                            return (
                                <li><button
                                    key={i}
                                    data-target={`target-${i}`}
                                    className={'toggle_button'}
                                    onClick={(e) => {
                                        const target = e.currentTarget.getAttribute('data-target');
                                        const isActive = e.currentTarget.classList.contains('active');
                                        document.querySelectorAll('.accordion_item, .toggle_button').forEach((element) => {
                                            element.classList.remove('active');
                                        });
                                        if (!isActive) {
                                            document.querySelector(`.accordion_item[data-target="${target}"]`).classList.toggle('active');
                                            e.currentTarget.classList.toggle('active');
                                        }
                                    }}
                                >{item.tabtext}</button></li>
                            )
                        })

                    }
                </ul>

                {

                    content.map((item, i, arr) => {
                        return (
                            <div
                                key={i}
                                data-target={`target-${i}`}
                                className={'accordion_item'}>
                                <button
                                    data-target={`target-${i}`}
                                    className={'toggle_button'}
                                    onClick={(e) => {
                                        const target = e.currentTarget.getAttribute('data-target');
                                        const isActive = e.currentTarget.classList.contains('active');
                                        document.querySelectorAll('.accordion_item, .toggle_button').forEach((element) => {
                                            element.classList.remove('active');
                                        });
                                        if (!isActive) {
                                            document.querySelector(`.accordion_item[data-target="${target}"]`).classList.toggle('active');
                                            e.currentTarget.classList.toggle('active');
                                        }
                                    }}
                                >{item.tabtext}</button>
                                {item.flexContent && item.flexContent.map((flexItem, j) => {
                                    const layoutType = flexItem?.fieldGroupName;
                                    const ComponentTag = layouts[layoutType] ? layouts[layoutType] : layouts['page_default']
                                    return (
                                        <ComponentTag
                                            item={flexItem}
                                            key={id + j + layoutType}
                                            arr={arr}
                                            num={j}
                                            {...data} />
                                    )
                                })}
                            </div>
                        )

                    })

                }
            </div>
        </>
    )
}

export default Component
