import React from 'react'
import Img from 'gatsby-image'
import parse from 'html-react-parser'
import Form from '../form/FormData'
const Component = (item, num) => {
  const images = item?.item?.imageMobile
    ? [
        item.item.imageMobile.localFile.childImageSharp.fluid,
        {
          ...item.item.image.localFile.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ]
    : item?.item?.image
    ? item.item.image.localFile.childImageSharp.fluid
    : false
return (
  <div className={`section form freehandle`}>
    {images && (
      <Img
        fluid={images}
        className="no-padding-image"
        alt={item.item.image.altText}
      />
    )}
    <div className="container">
      <div className={`row justify-content-${item.item.position}`}>
        <div className="col-12 col-md-6">
          {item?.item?.title && <h2>{parse(item?.item?.title)}</h2>}
          {item?.item?.text && parse(item?.item?.text)}
          <Form item={item} />
          {item?.item?.textBottom && parse(item?.item?.textBottom)}
        </div>
      </div>
    </div>
  </div>
)}

export default Component
