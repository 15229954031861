import React, { useRef, useLayoutEffect, useState } from 'react'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import useWindowDimensions from '../hooks/useWindowDimensions'

const Component = ({ children }) => {
  const { scrollY } = useViewportScroll()
  const ref = useRef()
  const [ boundingClientRect, setboundingClientRect ] = useState({bottom: 0, top: 0})
  const { width, height } = useWindowDimensions()

  useLayoutEffect(() => {
    setboundingClientRect(ref.current.getBoundingClientRect())
  }, [width, height])

const y = useTransform(
  scrollY,
  [0, boundingClientRect.height],
  ['0vw', '5vw']
)

  return (
    <div className="inner-wrap" ref={ref}>
      <motion.div
        className="motion-wrap"
        style={{
          translateY: y,
        }}
      >
        {children}
      </motion.div>
    </div>
  )
}

export default Component
