import React from 'react'
import parse from 'html-react-parser'
import { StaticQuery, Link, graphql } from 'gatsby'
import { motion } from 'framer-motion'

const replaceUrl = (url) => {
  return url.replace('https://persson.lebowski.se', '')
}

const Component = ({ item, num }) => (
  <StaticQuery
    query={graphql`
      query {
        wp {
          callToAction {
            cta {
              link {
                target
                title
                url
              }
              text
              title
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className={`section call-to-action`}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              {data?.wp?.callToAction?.cta?.title && (
                <h3 className="title">{data.wp.callToAction.cta.title}</h3>
              )}
              {data?.wp?.callToAction?.cta?.text && (
                <p>{parse(data.wp.callToAction.cta.text)}</p>
              )}
              {data?.wp?.callToAction?.cta?.link && (
                <Link
                  to={replaceUrl(data.wp.callToAction.cta.link.url)}
                  target={data.wp.callToAction.cta.link.target}
                >
                  <motion.button className="btn" whileTap={{ scale: 0.9 }}>
                    {data.wp.callToAction.cta.link.title}
                  </motion.button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

export default Component
