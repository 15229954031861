import React from 'react'
import Img from 'gatsby-image'
import parse from 'html-react-parser'
import logo from '../../../assets/images/FSC_C129567.png'

const Component = ({ item, num, pageContext }) => {
  return (
    <div className={`section sustainable`}>
      <div className="container">
        <div className="row justify-content-center justify-content-md-between align-items-center">
          <div className="col-12">
            {item?.title && <h2>{parse(item.title)}</h2>}
          </div>
          <div className="col-12 col-md-6 text">
            {item?.text && <div>{parse(item.text)}</div>}
          </div>
          <div className={`col-8 col-md-5`}>
            <div className="row justify-content-center justify-content-md-start">
              <div className="col-6 mb-5 mt-5 mt-md-0">
                <img src={logo} alt="FSC" className="img-fluid" />
              </div>
              <div className="col-12"></div>
              <div className="col-6 text-center">
                {item.image1 && (
                  <div className="image-wrap-contain">
                    <Img
                      fluid={item.image1.localFile.childImageSharp.fluid}
                      className="no-padding-image"
                      alt={item.image1.altText}
                    />
                  </div>
                )}
              </div>
              <div className="col-6 text-center">
                {item.image2 && (
                  <div className="image-wrap-contain">
                    <Img
                      fluid={item.image2.localFile.childImageSharp.fluid}
                      className="no-padding-image"
                      alt={item.image2.altText}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component
