import React, { useRef, useLayoutEffect, useState } from 'react'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import useWindowDimensions from '../hooks/useWindowDimensions'

const Component = ({ item }) => {
  const { scrollY } = useViewportScroll()
  const ref = useRef()
  const [ boundingClientRect, setboundingClientRect ] = useState({bottom: 0, top: 0})
  const [offsetTop, setOffsetTop] = useState(undefined)
  const { width, height } = useWindowDimensions()

  useLayoutEffect(() => {
    setboundingClientRect(ref.current.getBoundingClientRect())
    setOffsetTop(ref.current.offsetTop)
  }, [width, height, ref])

  const y = useTransform(
    scrollY,
    [offsetTop - height, offsetTop + boundingClientRect.height],
    ['6vw', '-6vw']
  )
  return (
    <div className={`section video`} ref={ref}>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className={`col-12 col-md-10 col-lg-10`}>
            <motion.div
              style={{
                translateY: y,
              }}
            >
              <video controls poster={item?.bg?.localFile?.publicURL}>
                {item?.webm?.localFile?.publicURL && (
                  <source
                    src={item.webm.localFile.publicURL}
                    type="video/webm"
                  />
                )}
                {item?.mp4?.localFile?.publicURL && (
                  <source src={item.mp4.localFile.publicURL} type="video/mp4" />
                )}
              </video>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component
