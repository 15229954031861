import React from 'react'
import Img from 'gatsby-image'

const Component = ({ item, num }) => {
return (
  <div className={`section offices`}>
    <div className="container">
      <div className="row">
        <div className="col-12">
          {item.title && <h3 className="title">{item.title}</h3>}
        </div>
      </div>
      {item?.offices &&
        item.offices.map((item, i) => (
          <div className="row row-office" key={i}>
            <div className="col-12 col-md-6">
              {item.title && <h4 className="title">{item.title}</h4>}
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
            <div className="col-12 col-md-6 map">
              <div className="map-image">
                <a href={item.imageLink} target="_blank">
                  <Img
                    fluid={item.image.localFile.childImageSharp.fluid}
                    className="no-padding-image"
                    alt={item.image.altText}
                  />
                </a>
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
)}

export default Component
