import React from 'react'
import parse from 'html-react-parser'
const Component = ({ item, num, pageContext }) => {
  return (
    <div className={`section content ${num === 0 && 'first'}`}>
      <div className="container">
        <div className="row justify-content-between">
          {item?.title && (
            <div className="col-12">
              <h2 className="title">{item.title}</h2>
            </div>
          )}
          {item?.text && (
            <div className={`col-12 text text-columns-${item?.columns}`}>
              {parse(item.text)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Component
