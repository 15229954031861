import React from 'react'
import parse from 'html-react-parser'
const Component = ({ item }) => (
  <div className={`section industries`}>
    <div className="container">
      <div className="row">
        <div className="col-12">
          {item?.rubrik && <h2 className="title">{item.rubrik}</h2>}
        </div>
        {item?.textTop && (
          <div className="col-12 col-text-top">{parse(item.textTop)}</div>
        )}
      </div>
      <div className="row">
        {item?.industry &&
          item.industry.map((item, i) => (
            <div key={i} className="col-12 col-sm-6 col-md-4 industry">
              <li>{item.text}</li>
            </div>
          ))}
        {item?.text && (
          <div className="col-12 col-text">{parse(item.text)}</div>
        )}
      </div>
    </div>
  </div>
)

export default Component
