import React from 'react'
import Img from 'gatsby-image'
import parse from 'html-react-parser'
const Component = ({ item, num, pageContext }) => {
  return (
    <div className={`section circle ${item?.bgColor ? 'bg' : ''}`}
      style={{ backgroundColor: item?.bgColor ?? 'transparent' }}>
        <div className="container">
        <div className="row justify-content-center justify-content-md-between align-items-center">
          <div
            className={`col-8 col-md-5 circle order-0 order-md-${item.position}`}
          >
            {item?.image && (
              <Img
                fluid={item.image.localFile.childImageSharp.fluid}
                className="no-padding-image circle-image"
                alt={item.image.altText}
              />
            )}
          </div>
          <div className="col-12 col-md-6 text">
            {item?.title && <h2>{parse(item.title)}</h2>}
            {item?.text && <div>{parse(item.text)}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component
